import React from "react";

import * as Component from "./commonComponents";
import InputField from "./inputField";



////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2020
////////////////////////////////

class DetailViewLine extends React.Component
{
	render() {
		return 	<tr>
					<td style={{width:"10%"}}><TextLabel value={this.props.label}/></td>											
					<td style={{width:"90%"}}>{this.props.element}</td>																
				</tr> 																												///
}	} 

class TextLabel extends React.Component 
{
  	render() {
        return 	<spaw className="textLabelDetailView">{this.props.value}</spaw> 													///
}	} 


export default class DetailView extends React.Component 
{

	constructor(props) 
	{
	    super(props);

		var data = {}, config = this.props.config;  
		if (this.props.data) for (var i=0; i<config.length; i++)
		{ 
			var column = config[i].column;
			if (column && !config[i].hideDetail)  
				data[column] = this.props.data[column]; 
		}

	    this.state = { blocked: false,  data:data};
  	}

	render() 
	{
		log("render");
		
		const handleSubmit = function(e) 
		{
			e.preventDefault();
			log("handleSubmit: "+JSON.stringify(this.state.data));

			var errorMessage = "", config = this.props.config;
			for (var i=0; i<config.length; i++) 
				if (!config[i].hideDetail)
					if (config[i].edit && config[i].edit.mandatory && !this.state.data[config[i].column])
						errorMessage+= "Fehlende Angabe für "+config[i].label+".\n";
			if (errorMessage)
				alert(errorMessage)		
			else
			{
				this.setState({blocked: true, data:this.state.data}); 
				this.props.onCommit(this.props.id, this.state.data, function(){
					this.setState({blocked: false, data:this.state.data}); 
				}.bind(this)); 
			}
		}.bind(this);

		const onChange = function(key, value)
		{
//			log("onChange: "+key+" "+value);
			this.state.data[key] = value;
		}.bind(this);

		var details = [], groups = {}, tabIndex= 1;  
		for (var i=0; i<this.props.config.length; i++) 
		{
			var config = this.props.config[i];
			if (!config.hideDetail )
			{
				var groupKey = config.render.group;  
				if (groupKey)
				{
					if (!groups[groupKey]) groups[groupKey]=[]; 
					groups[groupKey].push(config);  
				}
				else
				{ 
					var element = getInputElement(config, this.state, onChange, tabIndex);
					if (element)
                    {   
                        tabIndex++;
                        details.push(<DetailViewLine key={config.column}  element={element} label={config.label}/>)	
///
		}	}	}   }
		for (var key in groups)
		{
			var elementList = []; 
			for (var i=0; i<groups[key].length; i++)
			{
				var config = groups[key][i];
				var element = getInputElement(config, this.state, onChange, tabIndex, i);
				if (element)
				{
                    tabIndex++;
					elementList.push(element); 	
					elementList.push(<TextLabel value={config.label} key={"label"+i}/>); ///
				}
			}
			details.push(<DetailViewLine key={key} element={elementList} label={key}/>); ///
		}

		return (
			<form onSubmit={handleSubmit} >
			<spawn style={{fontSize:"50%"}}>{this.props.id?"edit "+this.props.id:"new"}</spawn>
			<input type="submit" style={{width:"0px", height:"0px", opacity:0}}/>        
			<table style={{background:"#fed", margin:"1%", width:"98%"}}> 
			<tbody>
			{details}
			</tbody></table>
			</form>   ///
		);
	}
}


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015
////////////////////////////////



function getInputElement(config, state, onChange, tabIndex, key)
{
	return <InputField  
				type={config.render.type} 
				key={key} 
				column={config.column} 
				blocked={state.blocked} 
				tabIndex={tabIndex} 
				require={config.edit?config.edit.mandatory:0}
				data={state.data[config.column]}
				onChange={onChange}
				_options={config.render._options}
				length={config.render.params?config.render.params[0]:undefined}
				otherTopic={config.render.params?config.render.params[0]:undefined}
			/>; /// 
}

/*function getInputElementOriginal(config, state, onChange, tabIndex, key)
{
	var element = 0;
	var required=config.edit?config.edit.mandatory:0, data=state.data[config.column];  
	var autoFocus = (tabIndex === 1);
        
	switch(config.render.type)
	{
		case (DataTypeReference): 
			element = <InputSelect key={key} column={config.column} blocked={state.blocked} tabIndex={tabIndex} autoFocus={autoFocus}
				required={required} data={data} options={config.render._options()} onChange={onChange}/>; ///
			break; 
		case (DataTypeListReference): 
			break;
		case (DataTypeBoolean): 
			element = <Component.ComponentInputBox key={key} column={config.column} blocked={state.blocked} tabIndex={tabIndex} autoFocus={autoFocus}
				required={required} data={data} onChange={onChange}/>; ///
			break;
		case (DataTypeExternalLink): 
			element = <Component.ComponentInputURL key={key} column={config.column} blocked={state.blocked} tabIndex={tabIndex} autoFocus={autoFocus}
				required={required} data={data} onChange={onChange}/>; ///
			break; 
		case (DataTypeDayTime):	
			element = <Component.ComponentInputTime key={key} column={config.column} blocked={state.blocked} tabIndex={tabIndex} autoFocus={autoFocus}
				required={required} data={data} onChange={onChange}/>; ///
			break; 
		case (DataTypeDate):	
			element = <Component.ComponentInputDate key={key} column={config.column} blocked={state.blocked} tabIndex={tabIndex} autoFocus={autoFocus}
				required={required} data={data} onChange={onChange}/>; ///
			break; 
		case (DataTypeText): 
			element = <InputText key={key} column={config.column} blocked={state.blocked} tabIndex={tabIndex} autoFocus={autoFocus} 
				required={required} data={data} length={config.render.params?config.render.params[0]:undefined} onChange={onChange}/>; ///
			break; 
		case (DataTypeTextarea): 
			element = <InputTextarea key={key} column={config.column} blocked={state.blocked} tabIndex={tabIndex} autoFocus={autoFocus} 
				required={required} data={data} onChange={onChange}/>; ///
			break; 
	}
	return element; 
}
*/

////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2020
////////////////////////////////

function log(text) {console.log("DetailView: "+ text)};
