"use strict";

import React from "react";
import ReactDOM from "react-dom";

//import * as Component from "./commonComponents";
import ContextTabTableView from "./ContextTabTableView";

// add this to not trigger eslint no-undef
/* global moment */
/* global $ */


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2021
////////////////////////////////


console.log("Start application"); 
moment.lang('de');

var managementFunctions = {
	"Excel Export": "/download"
}; 

export default class MyApplication extends React.Component
{
	constructor(props) 
	{
	    super(props);
    	this.state = {
    		API:null,
    		text:"Start Application ..",
    		data:null
    	};
	}

	componentDidMount() 
	{
		log("componentDidMount");
		try {
			$.ajax({
				url: "config", dataType: 'json',
				success: function(data) 
				{
					log("config-request: success");
			    	this.setState({
			    		API:true,
			    		text:"Application running..",
			    		data:data   //data.config, data.default 
			    	});

				}.bind(this),
				error: function(xhr, status, err) 
				{
					log("config-request: error");
			    	this.setState({
			    		API:false,
			    		text:"Fehler bei Serverrequest: "+err.toString(),
			    		data:null 
			    	});
				}.bind(this)
			});
		} 
		catch(error){
			log("config-request: catch");
	    	this.setState = ({
	    		API:null,
	    		text:error,
	    		data:null 
	    	});
		}
	}

	render()
	{
		if (null == this.state.API )
			return <div>{this.state.text}</div> ///
		else if (! this.state.API )
			return <div className="loginView">
						<div className="loginDisplay"/>
						<div className="loginSchattenMast"/>
						<div className="loginMast"/>
						<div className="loginDisplayOben">
								Flex-Notebook
						</div> 
						<div className="loginDisplayUnten">					
							<form method="post" action="/login" name="aform">
							<table className="loginDisplayUntenTabelle">
							<tr>
								<td>Login:</td>
								<td>
									<input className="loginInputField" type="text" name="username" placeholder="Benutzername"/>
								</td>
							</tr><tr>
								<td>Passwort:</td>
								<td>
									<input className="loginInputField" type="password" name="password" placeholder="Passwort"/>
								</td>
							</tr><tr>
								<td className="loginDisplayUntenTabelleLetzteZeile" colspan="2">
									<input className="loginSubmitButton" type="submit" value="Anmelden"/>
								</td>
							</tr>
							</table>
							</form>
						</div>
					</div>
		else	
			return <ContextTabTableView config={this.state.data.config} defaultContext={this.state.data.default} managementFunctions={this.props.managementFunctions}/> ///
	}
}


ReactDOM.render(<MyApplication managementFunctions={managementFunctions}/>, document.getElementById("root"));



////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2021
////////////////////////////////


function log(text) {console.log("MyApplication: "+ text)};

/*
function initLogin()
{
    function createChildElement(parent,type,styleData){
      var element = document.createElement(type); parent.appendChild(element);if (styleData) for (var key in styleData) element.style[key] = styleData[key]; return element; }

    function createLoginInputLine(label, type, name,  placeholder)
    {
      var tr = createChildElement(loginTable, "tr"); 
      createChildElement(tr, "td").innerHTML = label; 
      var input = createChildElement(createChildElement(tr, "td"), "input", {
        border: "1px solid #CCCCCC", borderRadius: "5px", color: "#666666", display: "inline-block", 
        fontSize: "1.00em", padding: "5px", width: "100%"
      });   
      input.type = type;
      input.name = name;
      input.placeholder = placeholder;   
      return input; 
    }

    function createLoginSpanLine(style) 
    {
      var colspanLine = createChildElement(createChildElement(loginTable, "tr"), "td", style); 
      colspanLine.setAttribute("colspan", 2); 
      return colspanLine; 
    }

    var backPanel = createChildElement(document.body, "div", {height: "100%", width:"100%"});  
    var left = Math.round((backPanel.offsetWidth-400)*.55);
    var top = Math.round(backPanel.offsetHeight*.2);
     
    createChildElement(backPanel, "div", {
        position:"absolute", top:(top+20)+"px", left:left+"px", height: "250px", width:"430px", 
        boxSizing: "border-box", display: "inline-block", backgroundColor: "#FFFFFF",
        border: "4px solid #D3D6FF", borderRadius: "5px", boxShadow: "0px 0px 8px #D3D6FF"});

    createChildElement(backPanel, "div", {
        position:"absolute", top:(top-3)+"px", left:(left-12)+"px", height: (backPanel.offsetHeight-top+10)+"px", 
        boxSizing: "content-box", 
        width:"14px", backgroundColor: "#aaaaaa", borderRadius: "5px 5px 0px 0px"});

    createChildElement(backPanel, "div", {
        position:"absolute", top:top+"px", left:(left-7)+"px", height: (backPanel.offsetHeight-top+7)+"px", width:"10px", 
        boxSizing: "content-box", 
        backgroundColor: "#dddddd", borderRadius: "5px 5px 0px 0px"});


    createChildElement(backPanel, "div", {
        position:"absolute", top:(top+24)+"px", left:(left+3)+"px", height: "20px", width:"400px", 
        boxSizing: "content-box", 
        backgroundColor: "#D3D6FF", borderRadius: "5px 5px 0px 0px", padding: "15px",
        textAlign: "center", color: "#666666", fontSize: "1.00em", fontWeight: "bold"
      }).innerHTML = "Flex-Notebook";

    var loginPanel = createChildElement(backPanel, "div", {
        position:"absolute", top:(top+75)+"px", left:(left+3)+"px", width:"400px", height:"168px", 
        boxSizing: "content-box", 
        backgroundColor: "#eeeeee", padding: "15px"
    }); 
    var loginForm = createChildElement(loginPanel, "form"); 
    loginForm.method = "post"; 
    loginForm.action="/login"; 
    loginForm.name="aform"; 
//    loginForm.target="_top"; 
    var loginTable = createChildElement(loginForm, "table", {
      width:"100%", color: "#064073", fontSize: "1.00em"
    }); 

//    console.log(document.body.offsetHeight); 

    createLoginInputLine("Login:", "text", "username", "Benutzername").focus();  
    createLoginInputLine("Passwort:", "password",  "password", "Passwort");  

    var loginSubmit = createChildElement(createLoginSpanLine({textAlign: "right"}), "input", {
      height: "auto", width: "auto", cursor: "pointer", boxShadow: "0px 0px 5px #D3D6FF", float: "right", marginTop: "10px",
      border: "1px solid #CCCCCC", borderRadius: "5px", color: "#666666", display: "inline-block", 
      fontSize: "1.00em", padding: "5px"
    });
    loginSubmit.type="submit"; 
    loginSubmit.value="Anmelden"; 

//    createChildElement(createLoginSpanLine(), "p", {color:"red"}).innerHTML= "{{message}}";
  }
*/