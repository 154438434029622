import React from "react";

// add this to not trigger eslint no-undef
/* global $ */
/* global createChildElement */
/* global findElement */
/* global appendElement */
/* global detachElement */


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2022
////////////////////////////////

const myDivId = "tableView";


function calcColumns(config)
{
  var columns = [];
  for (var i=0; i<config.length; i++)  
  {
    var columnConfig = config[i];  
	if (!columnConfig.hideList)  
		columns.push({
		  title: columnConfig.label,
		  data: columnConfig.column,
		  render: columnConfig.render._table
		});
  }
  return columns;
} 

function calcData(data, filter)
{
  log("calcData "+(filter?filter.column:""));
  var list =[];   
  if (!filter) 
      for (var key in data) 
        list.push(data[key]); 
  else
      for (var key in data) 
          if (data[key][filter.column] == filter.value)
              list.push(data[key]); 
      
  return list;
}

function createNewDataTable(parent, props)
{
  log("createNewDataTable");

  const table = createChildElement(parent, "table", {className:"display", width:"100%"});
  const dataTable = $(table).DataTable({
       rowId: 'id',
       pageLength: 25,
       data: calcData(props.data, props.filter),
       columns: calcColumns(props.config) 
  });
  $(table).children('tbody').on( 'click', 'tr', function () {
        log("click TR");
        if ( $(this).hasClass('selected') ) {
            $(this).removeClass('selected');
        }
        else {
            props.onSelectingRecord(dataTable.row( this ).id());
            dataTable.$('tr.selected').removeClass('selected');
            $(this).addClass('selected');
        }
  } );
  return dataTable;

}


export default class TableView extends React.Component
{

  constructor(props) 
  {
    super(props);
    this.myDiv = 0;
    this.myTables = {};
  }

  componentDidMount()
  {
	log("componentDidMount "+this.props.topic)
    this.myDiv = findElement(myDivId); 
    this.myTables[this.props.topic] = createNewDataTable(this.myDiv, this.props);
  }  

  componentWillUnmount()
  {
    log("componentWillUnmount ")
    for (var key in this.myTables)
       this.myTables[key].destroy(); 
  }

  shouldComponentUpdate(nextProps) 
  {
    log("shouldComponentUpdate "+this.props.topic+" "+nextProps.topic+ " Filter: "+nextProps.filter);
    const currentTable = this.myTables[this.props.topic];

    if ((this.props.topic != nextProps.topic))
    {
      log("-->table change ");
      detachElement(currentTable.table().container());
      const nextTable = this.myTables[nextProps.topic];
      if (nextTable)
      {
        appendElement(this.myDiv, nextTable.table().container());
         nextTable.clear();
         nextTable.rows.add(calcData(nextProps.data, nextProps.filter));
         nextTable.draw();
      }
      else
        this.myTables[nextProps.topic] = createNewDataTable(this.myDiv, nextProps);
    } 
    else if (!nextProps.data)
    {
      log("-->only clear");
      currentTable.clear();
      currentTable.draw();
    }
    else 
//      if ((!this.props.data) || Object.keys(nextProps.data).length != Object.keys(this.props.data).length)
    {
      log("-->reloadTableData");
      currentTable.clear();
      currentTable.rows.add(calcData(nextProps.data, nextProps.filter));
      currentTable.draw();
    }
    return false; // immer false, weil rendern über update oder reload passier
  }


  
	render() 
	{
		return (
			<div id={myDivId}></div> ///
    );	}

  
};


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2022
////////////////////////////////

function log(text) {console.log("TableView: "+ text)};
