import React from "react";
import TabTableController from "./tabTableController";

////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2020
////////////////////////////////

export default class ContextTabTableView extends React.Component 
{

	constructor(props) 
	{
	    super(props);
		log("constructor: "+this.props.defaultContext); 
//	    this.state = this._.contextChanged(this.props.defaultContext);
	    this.state = contextChanged(this.props.defaultContext);
	}
	
	componentWillReceiveProps(nextProps) 
	{
		log("componentWillReceiveProps"); 
  		this.setState(
//  			this._.contextChanged(nextProps.defaultContext));
  			contextChanged(nextProps.defaultContext));
	}

	render() 
	{
    	log("render "+this.state._currentContext);
		try {
			var handleContextClick = function(key) {
				this.setState(
//					this._.contextChanged(key));	
					contextChanged(key));	
			}.bind(this);

		    var createContextButton = function(key)
		    {
		    	var selected = (this.state._currentContext == key);
		    	var enabled = true; 
				var onClick = enabled && !selected ?function(){handleContextClick(key)}:0;
				var className = enabled ?(selected  ? "contextSelected": "context"):"contextDisabled"; 
				contextButtons.push(<div key={key} className={className} onClick={onClick}> {key} </div>);
///
		    }.bind(this); 

		    var createManagementButton = function(key, href)
		    {
		    	var fullHref = href + "/"+this.state._currentContext;
		    	managementButtons.push(<a key={key} className="cssManagementLink" href={fullHref}>{key}</a>); 
		    }.bind(this); 
///
		    var contextButtons = [], managementButtons = [];
		    for (var key in this.props.config) createContextButton(key); 
		  	for (var key in this.props.managementFunctions) createManagementButton(key, this.props.managementFunctions[key]); 

		    return (
		      <table className="fullWidthElement"><tbody><tr>
		        <td className="verticalMenu">
		          {contextButtons}
		          <br/>  
		          {managementButtons}
		        </td>
		        <td className="fullWidthElement">
		          <TabTableController context={this.state._currentContext} config={this.props.config[this.state._currentContext]}/>        
		        </td>
		      </tr></tbody></table>
		    );
		} 
		catch(error){
			return <div>{error}</div>;
		}
 
	}
///
/*
	_: {
		contextChanged: function(currentContext){
			return {
				_currentContext: currentContext,
			};}, 
	}*/

}
///
function contextChanged(currentContext)
{
	return {
		_currentContext: currentContext,
};} 

function log(text) {console.log("ContextTabTableView: "+ text)};
