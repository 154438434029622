import React from "react";
import ReactDOM from "react-dom";

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';

import * as Component from "./commonComponents";

// add this to not trigger eslint no-undef
/* global DataTypeText */
/* global DataTypeTextarea */
/* global DataTypeBoolean */
/* global DataTypeDate */
/* global DataTypeDayTime */
/* global DataTypeReference */
/* global DataTypeListReference */
/* global DataTypeExternalLink */


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2020
////////////////////////////////

class InputText extends React.Component 
{
  	render() {		
		return 	<input type="text" style={{width:"98%"}} id={this.props.column} disabled={this.props.blocked}						 
					tabIndex={this.props.tabIndex} autoFocus={this.props.autoFocus} required={this.props.required?"required":0}				
					defaultValue={this.props.data} 																						
					onChange={function(){this.props.onChange(this.props.column, ReactDOM.findDOMNode(this).value);}.bind(this)} />				
					  																											/// 
}	} 

class InputTextareaOLD extends React.Component 
{
  	render() {
		return <textarea style={{width:"98%"}} id={this.props.column} disabled={this.props.blocked} 		///
				tabIndex={this.props.tabIndex} autoFocus={this.props.autoFocus} required={this.props.required?"required":0}			
				defaultValue={this.props.data} 																						
				onChange={function(){this.props.onChange(this.props.column, ReactDOM.findDOMNode(this).value);}.bind(this)}			
				/>																													/// 
}	} 

class InputTextarea extends React.Component 
{
	componentDidMount()	{
		
		let data = 0;
		try {
			data = JSON.parse(this.props.data);
		} catch (error) {
			log(error);	
			data = {blocks: [{"type" : "paragraph", "data" : {"text" : this.props.data}}]};			
		}
		
		const editor = new EditorJS({
			holder: this.props.column,
			logLevel: "ERROR",
			data: data,
			onReady: () => {
			},
			onChange: async () => {
				editor.save().then((outputData) => {
					var data = JSON.stringify(outputData); 
					this.props.onChange(this.props.column, data);
				}); 
			},
			autofocus: true,
			tools: { 
				header: Header, 
				list: {
					class: List,
					inlineToolbar: true,
				},
			}, 
		});
    }	

	render() {
		return <div id={this.props.column} style={{width:"98%", backgroundColor:"white"}}/>

}	}


class InputSelect extends React.Component 
{
  	render() {
		var options = [];
		if (!this.props.required)
			options.push(<option key={""} value={""}>...</option>);								 									///
		for (var key in this.props.options)
			options.push(<option key={key} value={key} >{this.props.options[key]}</option>); 										///
		return <spawn>
				<select id={this.props.column} disabled={this.props.blocked} tabIndex={this.props.tabIndex} autoFocus={this.props.autoFocus}
					defaultValue={this.props.data} onChange={function(){this.props.onChange(this.props.column, event.target.value);}.bind(this)}>
				{options} 
				</select>  	
				<input type="button" className="topic" value="..." onClick={function(){handleGuiEvent("changeTopic",this.props.otherTopic)}.bind(this)} />
				</spawn>																					///
}	} 


export default class InputField extends React.Component 
{
	render() 
	{
		const key = this.props.key;		
		const column = this.props.column;
		const blocked = this.props.blocked;
		const tabIndex = this.props.tabIndex;
		const required = this.props.required;
		const data = this.props.data;
		const onChange = this.props.onChange;
		const _options = this.props._options;
		const length =  this.props.length;
		const otherTopic = this.props.otherTopic;

		const autoFocus = (tabIndex === 1);
		var element = 0;


		switch(this.props.type)
		{
			case (DataTypeListReference): 
				break;

			case (DataTypeBoolean): 
				element = <Component.ComponentInputBox key={key} column={column} blocked={blocked} tabIndex={tabIndex} autoFocus={autoFocus}
					required={required} data={data} onChange={onChange}/>; ///
				break;
			case (DataTypeExternalLink): 
				element = <Component.ComponentInputURL key={key} column={column} blocked={blocked} tabIndex={tabIndex} autoFocus={autoFocus}
					required={required} data={data} onChange={onChange}/>; ///
				break; 
			case (DataTypeDayTime):	
				element = <Component.ComponentInputTime key={key} column={column} blocked={blocked} tabIndex={tabIndex} autoFocus={autoFocus}
					required={required} data={data} onChange={onChange}/>; ///
				break; 
			case (DataTypeDate):	
				element = <Component.ComponentInputDate key={key} column={column} blocked={blocked} tabIndex={tabIndex} autoFocus={autoFocus}
					required={required} data={data} onChange={onChange}/>; ///
				break; 

			case (DataTypeReference): 
				element = <InputSelect key={key} column={column} blocked={blocked} tabIndex={tabIndex} autoFocus={autoFocus}
					required={required} data={data} options={_options()} onChange={onChange} otherTopic={otherTopic}/>; ///
				break; 
			case (DataTypeText): 
				element = <InputText key={key} column={column} blocked={blocked} tabIndex={tabIndex} autoFocus={autoFocus} 
					required={required} data={data} length={length} onChange={onChange}/>; ///
				break; 
			case (DataTypeTextarea): 
				element = <InputTextarea key={key} column={column} blocked={blocked} tabIndex={tabIndex} autoFocus={autoFocus} 
					required={required} data={data} onChange={onChange}/>; ///
				break; 
		}

		return (
			element
		);
	}
}

////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2020
////////////////////////////////


function log(text) {console.log("InputField: "+ text)};
