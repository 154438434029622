
import React from "react";

import EditorJS from '@editorjs/editorjs';
// import Header from '@editorjs/header';

import * as Component from "./commonComponents";


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2022
////////////////////////////////

const myDivId = "documentView";
var myEditor = null; 

export default class DocumentView extends React.Component
{
	initEditor()
	{	
		const editor = new EditorJS(
		{
			holder: myDivId,
			logLevel: "ERROR",
			data: {},
			onReady: () => {
				myEditor = editor;
				prepareData(this.props);
			},
			onChange: async () => {
//				let content = await this.editorjs.saver.save();
				// Put your logic here to save this data to your DB
//				setEditorData(content);
			},
			autofocus: true,
			tools: { 
//				header: Header, 
			}, 
		});
	};

	componentDidMount()
	{
		log("componentDidMount")
		if (!myEditor) this.initEditor();
    }	
		
	componentWillUnmount()
	{
		log("componentWillUnmount ")
		if (myEditor)
		{		
			myEditor.destroy();
			myEditor = null;
	}	}
	

	shouldComponentUpdate(nextProps) 
	{
		log("shouldComponentUpdate "+nextProps.topic)
		prepareData(nextProps);
		return false; 
	}  


	render() 
	{
		log("render "+this.props.topic);
		
		var onSave = function()
        {
			myEditor.save().then((outputData) => {
				
				console.log(outputData);	
				const blocks = outputData.blocks;
				const cnt =  blocks.length; 
				log (cnt+" Blocks"); 
				
				var data = [];
				for (var i = 0; i< cnt; i++)    
				{
					const record = {id: blocks[i].id.length == 36 ? blocks[i].id : ""};  // Hack, um "neue Einträge" zu kennzeichnen
					record[this.props.columnDocumentView] = blocks[i].data.text;	
					data.push(record);
				}
				console.log(data);
				this.props.onCommit(data, function(error){log(error);}); 

			}).catch((error) => {
			  console.log('Saving failed: ', error)
			});
			
        }.bind(this);
		
		return (
			<table className="fullWidthElement"><tr>
			<td className="fullWidthElement">
				<div id={myDivId}/>
			</td><td>
				<Component.UIComponentButton key="DocumentViewSpeichern" label="Speichern" variant="contained" color="secondary" onClick={onSave} />
			</td>
			</tr></table>
	)}
};


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2022
////////////////////////////////

function prepareData(props)
{
	log("prepareData "+props.columnDocumentView); 
	
	var config = [];
	for (var i=0; i<props.config.length; i++)  
	{
		var columnConfig = props.config[i]; 
		if (columnConfig.render.type == DataTypeReference)
			columnConfig.render.options = columnConfig.render._options();
		config.push(columnConfig);
	}

	var list =[];   
	if (!props.filter) 
		for (var key in props.data) 
			list.push(prepareDataRecord(props.data[key], config, props.columnDocumentView));  /* NUR EINE SPALTE */ 
	else
		for (var key in props.data) 
			if (props.data[key][props.filter.column] == props.filter.value)
				list.push(prepareDataRecord(props.data[key], config, props.columnDocumentView));  /* NUR EINE SPALTE */ 

	var blocks = [];
/*	{
		"type" : "header",
		"data" : {
			"text" : props.topic + " ("+props.columnDocumentView+")", 
			"level" : 2
		}
	}]; 
*/	
	list.map( (record) => {
		record.data.map( (item) => {
			blocks.push(
			{
				"type" : "paragraph",
				"data" : {"text" : item},
				"id": record.id
		})})
	});

	
	myEditor.render({blocks:blocks}).then(
		function()
		{
			log (myEditor.blocks.getBlocksCount()+" Blocks"); 
	});
}

function prepareDataRecord(data, config, columnDocumentView)
{
	var record = {id:data.id, data:[]};	
	config.map(column => {
		
	/* NUR EINE SPALTE */ if (column.column == columnDocumentView)  /* NUR EINE SPALTE */ 
	{
		
		var value = data[column.column];
		var valueToShow = "";
		
		switch(column.render.type)
		{
			case (DataTypeListReference): 
				break;
			case (DataTypeBoolean): 
				valueToShow = /* column.label+": "+ */(value?"ja":"nein");
				break;
			case (DataTypeExternalLink): 
				valueToShow = /* column.label+": "+ */value;
				break;
			case (DataTypeDayTime):	
				valueToShow = /* column.label+": "+ */value;
				break;
			case (DataTypeDate):	
				valueToShow = /* column.label+": "+ */value;
				break;
			case (DataTypeReference): 
				valueToShow = /* column.label+": "+ */(value?column.render.options[value]:"");
				break;
			case (DataTypeText): 
			case (DataTypeTextarea): 
				valueToShow = /* column.label+": "+ */(value?value:"");
				break;
		}
		
		record.data.push(valueToShow);
	}
	});			

	return record;
}


function log(text) {console.log("DocumentView: "+ text)};



