import React from "react";
import ReactDOM from "react-dom";

// add this to not trigger eslint no-undef
/* global Modernizr */

////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015-2022
////////////////////////////////

/**
    @props label 	    label, e.g. "Speichern"
    @props onClick	    callback function
*/
export class UIComponentButton extends React.Component 
{
	render() 
	{
		var className = "topicButton";	

		if (this.props.variant == "text")
			className = "textButton";
		else if (this.props.variant == "outlined")
			className = "topicSelectedFilteredButton";
		else if (this.props.variant == "contained")
			switch (this.props.color) {
				case "warning":
					className = "parentTopicButton";
					break;
				case "secondary":
					className = "topicSelectedButton";
					break;
				default: 		
			}		
						
        return <input key={this.props.key} className={className} type="button" value={this.props.label} onClick={this.props.onClick} />
	}
}


/**
    @props label 	    label, e.g. "Bezeichnung"
    @props onClick	    callback function
    @props selected     whether the column is selected or not
    @props columnState  sort status 
*/
export class ComponentTableColumnHeader extends React.Component 
{
  render() 
    {
        var className = "headerCell";
        switch (this.props.columnState)
        {
        	case("sorted"): className = "headerCellSorted"; break;
            case("sortedReverse"): className = "headerCellSortedReverse"; break;
        }
        return <th className={className} onClick={this.props.onClick}>{this.props.label}</th>  ///
    }
} 


/**
	@props value 		number percent, e.g. "12.34" 
	@props symbol 		one symbol for 2%
*/
export class ComponentPercentValue extends React.Component 
{
  render() 
    {
		var number,image="";
		if (!this.props.value) number = "-";
		else
		{
			for (var i=0; i<Math.abs(this.props.value*2); i++)
				image += this.props.symbol; 
			number = this.props.value+" %"; 
	  	}
	  	return 	<spaw style={{color:'#880'}}>
			    {image}&nbsp;
			    <spaw style={{color:'gray', fontSize:'80%'}}>
			    {number}
			    </spaw></spaw>
	}
}




/**
	@props column 				string - id
	@props blocked				disabled?
	@props tabIndex
	@props autoFocus
	@props required
	@props data					string - default value
	@props onChange
*/
export class ComponentInputURL extends React.Component 
{
  render() 
    {
//		console.log("ComponentInputURL.render "+this.props.column+" "+this.props.length); 
		return <input type="url" size={150} 
				id={this.props.column} disabled={this.props.blocked} 
				tabIndex={this.props.tabIndex} autoFocus={this.props.autoFocus}
				required={this.props.required?"required":0}	
				defaultValue={this.props.data} 
				onChange={function(){this.props.onChange(this.props.column, ReactDOM.findDOMNode(this).value);}.bind(this)}/>;
	}	
} 

/**
	@props column 				string - id
	@props blocked				disabled?
	@props tabIndex
	@props autoFocus
	@props required
	@props data					
	@props onChange
*/
export class ComponentInputTime extends React.Component 
{
  render() 
    {
    	var twoDigits = function(value){return value.length<2?"0"+value:value}
    	var data = this.props.data;
		return <span><input type="time"   
				id={this.props.column} disabled={this.props.blocked}
				tabIndex={this.props.tabIndex} autoFocus={this.props.autoFocus}
				required={this.props.required?"required":0}	
				defaultValue={data?(twoDigits(data[0])+":"+twoDigits(data[1])):undefined}
				placeholder="23:59" 
				onChange={function(){
					var data = ReactDOM.findDOMNode(this).children[this.props.column].value.split(":"); 
					while (data.length < 3) data.push(0);
					this.props.onChange(this.props.column, data);}.bind(this)}/>
				&nbsp;h</span>;  ///
	}		
} 

export class ComponentInputDate extends React.Component 
{
  render() 
	{
//    	var twoDigits = function(value){return value<10?"0"+value:value}

    	var data = this.props.data, defaultValue;
    	if (data)
    	{
//	    	var year=data[0], month=twoDigits(data[1]), day=twoDigits(data[2]);
	    	var year=data[0], month=data[1], day=data[2];
	    	if (Modernizr.inputtypes.date)
	    		defaultValue = year+"-"+month+"-"+day;
	    	else
	    		defaultValue = day+"."+month+"."+year;
	    }
		return <input type="date"   
				id={this.props.column} disabled={this.props.blocked} 
				tabIndex={this.props.tabIndex} autoFocus={this.props.autoFocus}
				required={this.props.required?"required":0}	
				defaultValue={defaultValue}
				placeholder={Modernizr.inputtypes.date?"2015-12-31":"31.12.2015"} 
				onChange={function(){
					var data = ReactDOM.findDOMNode(this).value;
					if (data)
					{					
						if (Modernizr.inputtypes.date)
							data = data.split("-"); 
						else
						{
							data = data.split("."); 
							while (data.length < 3) data.push(0);
							data.reverse(); 
						}
					}
					console.log(data);  
					this.props.onChange(this.props.column, data);}.bind(this)}/>
	}		
} 

export class ComponentInputBox extends React.Component 
{
  render() 
	{
		return <input type="checkbox" 
				id={this.props.column} disabled={this.props.blocked}
				tabIndex={this.props.tabIndex} autoFocus={this.props.autoFocus}
				checked={this.props.data} 
				onChange={function(){this.props.onChange(this.props.column, ReactDOM.findDOMNode(this).checked);}.bind(this)}/>;
	}	
}

