import React from "react";
import TabTableView from "./tabTableView";

// add this to not trigger eslint no-undef
/* global $ */
/* global initSocket */


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2020
////////////////////////////////

var mySocket; 

export default class TabTableController extends React.Component
{

  constructor(props) 
  {
    log("constructor "+props.context);
    super(props);
    this.state = {
      context: props.context,
      config: props.config,
      data:{},      
    };
  }  

  manageDataDownload(key)
  {
    log("manageDataDownload");
    var loadData = function(key)
    {
      //  console.log("loadData "+key); 
      $.ajax({
        url: key+".json", dataType: 'json',
        success: function(data) {
          log("data download '"+key+"' erfolgreich");
          var container = this.state.data;
          container[key] = data; 
          this.setState({data:container});
        }.bind(this),
        error: function(xhr, status, err) {
          console.error(key, status, err.toString());
        }.bind(this)
      });
    }.bind(this); 

    //  console.log("manageDataDownlaod "+key); 
    loadData(key)
    mySocket.on('/'+key, function(){loadData(key)}.bind(this));
  }

  componentDidMount() 
  {
    log("componentDidMount");

    mySocket = initSocket();
    mySocket.on("unauthorized", function(){
      window.location.reload();
    }); 
    for (var key in this.state.config.topics)  
      this.manageDataDownload(key); 

  }

  componentWillReceiveProps(nextProps) 
  {
    log("componentWillReceiveProps");
    this.setState({
      context: nextProps.context,
      config: nextProps.config,
    });

    for (var key in nextProps.config.topics)  if (!this.state.data[key])
      this.manageDataDownload(key); 
  }

  onUpdate(entity, data, callback)
  {
    log("onUpdate");
  //  console.log("onUpdate "+entity+" "+JSON.stringify(data)); 
      $.ajax({
        type: "POST",
        url: entity, data:{data:data},
        success: function() {
          callback(null); 
        }.bind(this),
        error: function(xhr) {
          //  console.log("onUpdate - error: "+xhr.responseText);
          callback(xhr.responseText); 
        }.bind(this)
      });
  }

  onDelete(entity, id, callback)
  {
    log("onDelete");
      //  console.log("onDelete "+entity+" "+id); 
      $.ajax({
        type: "DELETE",
        url: entity,data:{id:id},
        success: function() {
          callback(null); 
        }.bind(this),
        error: function(xhr) {
          //  console.log("onDelete - error: "+ xhr.responseText);
          callback(xhr.responseText); 
        }.bind(this)
      });
  }


  onLogout()
  {
    log("onLogout");
      //  console.log("onLogout"); 
      $.ajax({
        type: "GET",
        url: "logout",
        success: function() {
          window.location.reload(); 
        },
        error: function(xhr) {
          //  console.log("onLogout - error: "+ xhr.responseText);
        }
      });
  }

  render() 
  {
    log("render "+this.state.context);
    try {
      return (
        <TabTableView onUpdate={this.onUpdate} onDelete={this.onDelete} onLogout={this.onLogout} context={this.state.context} config={this.state.config} data={this.state.data}/>
      ); 
    } 
    catch(error){
      return <div>{error}</div>;
    }
  } 
} 

function log(text) {console.log("TabTableController: "+ text)};
