import React from "react";

import DetailView from "./detailView";
import TableView from "./tableView";
import DocumentView from "./documentView";

import * as Component from "./commonComponents";

// add this to not trigger eslint no-undef
/* global moment */
/* global registerGuiListener */
/* global DataTypeText */
/* global DataTypeTextarea */
/* global DataTypeBoolean */
/* global DataTypeDate */
/* global DataTypeDayTime */
/* global DataTypeReference */
/* global DataTypeListReference */
/* global DataTypeExternalLink */
/* global DataTypeListArray */
/* global DataTypePercent */
/* global DataTypePicture */

////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2022
////////////////////////////////


export default class TabTableView extends React.Component
{

  constructor(props) 
  {
    log("constructor "+props.context+"/"+props.config.default);
    super(props);

    this.prepareRenderFunctions();
    prepareRenderFunctionsForAllTopicsIfNeeded(props.config);

    this.state = {
		context:         this.props.context,
		parentTopic:     false,
		currentTopic:    this.props.config.default,
		currentFilter:   false,  
		currentId:       false,
		viewModus: 		 false,		
		showDetailView:  false
    }; 
  }

  prepareRenderFunctions()
  {
    log("prepareRenderFunctions");

    createReferenceOptionsFunction = function(params){
		
	  log("createReferenceOptionsFunction"); 	
      var otherTopic = params[0], otherColumn = params[1], noValue=params[2];  
      return function (data, type, full, meta) {
        var result = {}, otherDataSet = this.props.data[otherTopic];
        if (noValue) 
          result[0]=noValue;    
        if (otherDataSet) 
          for (var key in otherDataSet)
            result[key] = otherDataSet[key][otherColumn]; 
        return result; 
    }.bind(this);}.bind(this);


    mapTableRenderFunction[DataTypeBoolean] = function()  
    {
      return function (data, type, full, meta) {
        var is_checked = data === true ? "checked" : "";
        return (type === "sort") ? data : ('<input type="checkbox" disabled ' + is_checked + ' />');
    } };

    mapTableRenderFunction[DataTypePercent] = function(params)  
    {
      var symbol = params[0];
      return function (data, type, full, meta) {
        return <Component.ComponentPercentValue value={data} symbol={symbol}/>; ///
    } };

    mapTableRenderFunction[DataTypeText] = function(params) 
    {
      return function (data, type, full, meta) {
        return data;
    } };

    mapTableRenderFunction[DataTypeTextarea] = function(params) 
    {
      return function (data, type, full, meta) {
        var result = data;
        if (result && result.length > 60)
            result = result.substr(0,58)+"..";
        return result;
    } };

    mapTableRenderFunction[DataTypeDayTime] = function(params) 
    {
      function twoDigits(value){return value.length<2?"0"+value:value}
      return function (data, type, full, meta) {
        var dayTime = data;
        return dayTime? twoDigits(dayTime[0]) +":"+twoDigits(dayTime[1])+"h" : "";
    } };

    mapTableRenderFunction[DataTypeDate] = function(params) 
    {
      return function (data, type, full, meta) {
        var format = params ? params[0] : "dd DD.MM.YYYY";     
        var date = data ? new Date(parseInt(data[0]), parseInt(data[1])-1, parseInt(data[2]), 0, 0, 0) : 0;   
        //return date?date.getTime():0;
        return (type === "sort") ? (date?date.getTime()/1000:0) : (date?moment(date).format(format):"");  
    } };


    mapTableRenderFunction[DataTypePicture] = function(params)  
    {
      var map = params[0];
      return function (data, type, full, meta) {
        var key = data;  
        return key && map[key]? <img src={"application/res/"+map[key]} className="companyLogo" ></img>:key; ///
    } }; 

    mapTableRenderFunction[DataTypeListArray] = function(params) 
    {
      var trennzeichen = params[0];
      return function (data, type, full, meta) {
        var result = "", valueList = data; 
        if (valueList)
          for (var i=0; i< valueList.length; i++)
          {
            if (i>0) result += trennzeichen;
            result += valueList[i];
          }
          return result; 
    }};

    mapTableRenderFunction[DataTypeReference] = function(params) 
    {
      var otherTopic = params[0], otherColumn = params[1];
      return function (data, type, full, meta) {
        var result = "", otherDataSet = this.props.data[otherTopic]; 
        if (otherDataSet)
        {
            var otherObject=otherDataSet[data]; 
            if (otherObject)
            {
             result = '<a class="linkReference" onClick="handleGuiEvent(\'showReference\',\''+otherTopic+'\',\''+otherObject.id+'\')">'+otherObject[otherColumn]+'</a>' 
        }   }
      return result; 
    }.bind(this);}.bind(this);

///   !!!
    mapTableRenderFunction[DataTypeListReference] = function(params) {
      var label = params[0], otherTopic= params[1], filterColumn = params[2], cntReferenceColumn = params[3]; 
      return function (data, type, full, meta) {
//      return function(datarecord, column){
//        var counterLabel = (cntReferenceColumn?"("+datarecord[cntReferenceColumn]+")":"");
      var counterLabel = "";
        return ('<a class="linkReferenceList" onClick="handleGuiEvent(\'changeTopic\',\''+otherTopic+'\',\''+filterColumn+'\',\''+data+'\')">'+label+counterLabel+'</a>');  
    }.bind(this);}.bind(this);  

///   !!!!
//    mapTableRenderFunction[DataTypeExternalLink] = function(params)  
/*{
      return function (data, type, full, meta, datarecord) {
        if (!data) return "";
///!        var label = params && params[0]?datarecord[params[0]]:"link";
        return <a target="_" className="linkDownload" href={data}>{label}</a>;  //
    }}; */


    const me = this;  

    registerGuiListener("showReference", function (otherTopic, id, notUsed)
    {
      log("GuiListener showReference");
        me.setState({
          currentTopic: otherTopic,
          parentTopic: false,
          currentId: undefined,
          currentFilter: {column:"id", value:id},
			showDetailView: false,
			viewModus: false  
        }); 
    });

    registerGuiListener("changeTopic", function (otherTopic, filterColumn, filterId)
    {
      me.setState({
        currentTopic:     otherTopic,
        parentTopic:     me.props.config.topics[otherTopic].button ? false : me.state.currentTopic, 
        currentId:       false,
        currentFilter:   (filterColumn && filterId) ? {column:filterColumn, value:filterId} : false, 
			showDetailView: false,
			viewModus: false  
      }); 
    });
  }


  componentWillReceiveProps(nextProps) 
  {
    log("componentWillReceiveProps"); 

    if (nextProps.context != this.state.context)
    {
      prepareRenderFunctionsForAllTopicsIfNeeded(nextProps.config);

      this.setState({
          context:          nextProps.context,
          currentTopic:     nextProps.config.default,
          parentTopic:     false,
          currentFilter:   false,  
          currentId:       false,
			showDetailView: false,
			viewModus: false  
      });
    }
  }

  render() 
  {
    log("render "+this.state.context+"/"+this.state.currentTopic);

    try {

        var onCommitDetailView = function(id, data, callback) 
        {
          log("onCommitDetailView");
          data.id = id; 
          this.props.onUpdate(this.state.currentTopic, [data], function(error){
            callback(); 
            if (error) alert(error);
            else 
              this.setState({
                showDetailView: false,
              });
          }.bind(this));
        }.bind(this);
		
		var onCommitDocumentView = function(data, callback)
		{
			log("onCommitDocumentView");
			this.props.onUpdate(this.state.currentTopic, data, function(error){
            callback(); 
            if (error) alert(error);
            else 
              this.setState({
 //               showDetailView: false,
              });
          }.bind(this));
        }.bind(this);

        var onDeleteDetailView = function() 
        {
          log("onDeleteDetailView"); 
          if(window.confirm("Element wirklich löschen?"))
            this.props.onDelete(this.state.currentTopic, this.state.currentId, function(error){
              if (error) alert(error);
              else 
                this.setState({
                  currentId: undefined, 
                  showDetailView: false,
                });
            }.bind(this));
        }.bind(this);

        var onShowReportRequest = function() 
        {
          log("onShowReportRequest"); 
          this.props.onShowReportRequest(this.state.currentTopic, this.state.currentId, function(error){
              if (error) alert(error);
              else 
                this.setState({
                  currentId: undefined, 
                  showDetailView: false,
                });
          }.bind(this));
        }.bind(this);
	

        var onLogout = function()
        {
            this.props.onLogout(); 
        }.bind(this);

		var onChangeViewModus= function()
        {
			this.setState({
                currentId: undefined, 
				viewModus: !this.state.viewModus 	
			});
		}.bind(this);



        var topicButtonsToShow = [];

        for (var key in this.props.config.topics)  
        {
			
          var topic = this.props.config.topics[key]; 
          if (!topic.hideEmptyColumns || (this.props.data && this.props.data[key] && this.props.data[key].length))  // für München
          if (topic.button)  // Möglichkeit zum Nicht-Zeigen von Enums  
          {  
            if (this.state.parentTopic==key)
              topicButtonsToShow.push({
				  key:key, 
				  label:topic.label +" - "+ this.props.config.topics[this.state.currentTopic].label, 
				  variant: "contained",
				  color: "warning",
			  });
            else if (this.state.currentTopic==key)  // aktuelles Topic (direkt)
              topicButtonsToShow.push({
				  key:key, 
				  label:topic.label,
				  variant: this.state.currentFilter?"outlined":"contained",
				  color: "secondary",
			  });
            else // Link zu neuen Topic
              topicButtonsToShow.push({
				  key:key, 
				  label:topic.label, 
				  variant: "contained",
				  color: "primary",
			  });
		  } 
		}

        var actionButtonsToShow = [];

        if (this.props.config.topics[this.state.currentTopic].details)
        {
          if (!this.state.showDetailView || this.state.currentId)
			actionButtonsToShow.push({
				label:"Neu",
				onClick:function(){
					log("actionButton NEU");
					this.setState({showDetailView: 1, currentId: undefined});
				  }.bind(this)
			});  
			  

          if (!this.state.showDetailView && this.state.currentId)
			actionButtonsToShow.push({
				label:"Bearbeiten",
				onClick:function(){
					log("actionButton BEARBEITEN");
					this.setState({showDetailView: 1})}.bind(this)
			});  

          if (this.state.showDetailView)
			actionButtonsToShow.push({
				label:"Abbrechen",
				onClick:function(){
					log("actionButton Abbrechen");
					this.setState({showDetailView: 0, currentId: undefined})}.bind(this)
			});  

          if (this.state.currentId)
			actionButtonsToShow.push({
				label:"Löschen",
				onClick:onDeleteDetailView
			});  

        }


        var currentTopic = this.props.config.topics[this.state.currentTopic];

	var onRequestReport = function()
        {
		location.href="/report/"+this.state.currentTopic+"/"+this.state.currentId;
        }.bind(this);

        if (this.state.currentId && currentTopic.showReport)
			actionButtonsToShow.push({
				label:"Report",
				onClick:onRequestReport
			});  



///
        var currentConfig = currentTopic.config;

        var defaultSortColumn = 0; 
        for (var i=0; i<currentConfig.length; i++)
          if (currentConfig[i].column == currentTopic.defaultSortColumn)
            defaultSortColumn = i; 

///


        var currentData = this.props.data[this.state.currentTopic];

        var currentDataDetail;
        if (this.state.currentId) 
          currentDataDetail = currentData[this.state.currentId];
        else
        {
          currentDataDetail = {};
          currentDataDetail[this.state.currentFilter.column] = this.state.currentFilter.value;
        }



        const myTopic = this.state.currentTopic;  
          
		  return (
              <div>
                  <table className="fullWidthElement"><tr><td>

					{topicButtonsToShow.map(item => (
					   <Component.UIComponentButton key={item.key} label={item.label} variant={item.variant} color={item.color} onClick={function(){handleGuiEvent("changeTopic",item.key)}} />
					))}
					
					&nbsp;&nbsp;&nbsp;
					
					{actionButtonsToShow.map(item => (
					   <Component.UIComponentButton key={item.label} label={item.label} variant="text" onClick={item.onClick} />
					))}

				  </td><td style={{textAlign: "right"}}>

					{currentTopic.columnDocumentView 
					? <Component.UIComponentButton key="ViewModus" label={this.state.viewModus?"Tabelle":"Dokument"} variant="text" onClick={onChangeViewModus}/>
					: ""
					}					
					  
					<Component.UIComponentButton key="Abmelden" label="Abmelden" variant="text" onClick={onLogout} />
				  					
				</td></tr></table>
                
				{this.state.showDetailView 
				? <DetailView key={this.state.currentTopic+"-"+this.state.currentId} id={this.state.currentId} config={currentConfig} 
					onCommit={onCommitDetailView} data={currentDataDetail}/>
                : ""
				}
				
				{this.state.viewModus 				
				? <DocumentView topic={this.state.currentTopic} data={currentData} config={currentConfig} filter={this.state.currentFilter} 
					columnDocumentView={currentTopic.columnDocumentView} onCommit={onCommitDocumentView}/>
				: <TableView topic={this.state.currentTopic} data={currentData} currentId={this.state.currentId} config={currentConfig} 
                    defaultSortColumn={defaultSortColumn}
                    filter={this.state.currentFilter}
                    onSelectingRecord={function(id){
                      const relevant = (this.state.currentTopic == myTopic); 
                      log("onSelectingRecord "+relevant); 
                      if (relevant)
                          this.setState({currentId: id}); 
                    }.bind(this)}/>
				}	
					
              </div>  
          );
   
      } catch(error){
          log("render error..."+error)

          return <div>{error}</div>;
        }
    }
}


////////////////////////////////////////////////
// Dr. Matthias Bleyl, 2015 - 2020
////////////////////////////////

const mapTableRenderFunction = {};    
var createReferenceOptionsFunction = null; 

function prepareRenderFunctionsForAllTopicsIfNeeded(context)
{
  for (var key in context.topics)
  {  
    var topic = context.topics[key];
    if (!topic._renderFunctionCreated)
    {
      log("prepareRenderFunctions for "+key);

      for (var i=0; i<topic.config.length; i++)
      {
        var config = topic.config[i];  
        if (!config.render) 
          config.render = {type: DataTypeText}; 
      
        var renderFunction = mapTableRenderFunction[config.render.type];
        if (!renderFunction)
        {
          log("!! keine renderFunction für "+config.render.type);
          renderFunction = mapTableRenderFunction[DataTypeText];
        }  
        config.render._table = renderFunction(config.render.params);
        if (config.render.type == "reference") 
          config.render._options = createReferenceOptionsFunction(config.render.params);
      }
      topic._renderFunctionCreated = true;
    }
  }  
};

function log(text) {console.log("TabTableView: "+ text)};
